@import '../../styles/variables.scss';

video#homepage-bgvid {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  z-index: -100;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-color: #f9f7f9;
  background-size: cover;
}

.homepage-jumbotron {
  position: relative;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.homepage-logo-text {
  text-align: center;
  color: #0C344E;
  font-family: $font-primary;
  font-size: 32px;
  text-align: center;
  font-weight: 400;
}

.intro-section {
  padding: 100px 0 100px 0;
}

.intro-h1 {
  font-family: $font-primary;
  font-size: 28px;
  text-transform: uppercase;
  color: #0C344E;
  margin-bottom: 20px;
}

.intro-h2 {
  font-family: $font-primary;
  font-size: 20px;
  color: #0C344E;
}

.intro-text {
  font-family: $font-primary;
  font-size: 16px;
  color: #0C344E;
  font-weight: 300;
  line-height: 2;
}

hr.intro-line {
  border-top: 2px solid #DAD6C4;
  width: 80%;
}

.intro-h4 {
  font-family: $font-primary;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-logo-green;
  margin-bottom: 20px;
}

.intro-h3 {
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 600;
  color: #0C344E;
}

.introtop-h2 {
  color: #0C344E;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-style: italic;
  text-align: center;
  font-weight: 400;
}

.intro-featuredin-img {
  height: 20px;
}

.intro-featuredin {
  background-color: #ECEDF2;
  padding: 25px 0 25px 0;
}

.intro-icon {
  padding: 20px 0 20px 0;
}

.intro-text-icontitle {
  text-align: center;
  font-family: $font-primary;
  font-size: 14px;
  color: #0C344E;
  text-transform: uppercase;
  font-weight: 600;
}

.intro-text-icon {
  text-align: center;
  font-family: $font-primary;
  font-size: 14px;
  color: #0C344E;
  font-weight: 300;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

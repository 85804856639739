// custom variables
@import './variables.scss';

$body-bg: #E8ECF0;
$body-color: $color-logo-darkblue;
$table-color: $color-logo-darkblue;
//$table-color: $color-logo-darkblue;
$font-family-sans-serif: $font-primary;
$headings-font-weight: 300;
$headings-color: $color-primary;

$theme-colors: (
        "primary": $color-primary,
        "secondary": #A5A5A5,
        "success": $color-logo-green,
        "info": $color-logo-darkblue,
        "warning": $color-logo-red,
        "danger": $color-logo-red,
        "light": #FFF,
        "dark": $color-logo-darkblue,
);

.table-light {
  color: $color-logo-darkblue !important;
}

@import "./variables.scss";

.table .thead-light th {
  color: #76909f !important;
  //background-color: #f7f7f7;
}

.table th, .table td {
  //color: $color-logo-darkblue;
  font-size: 14px;
  border-bottom: solid 1px #e9e7e1 !important;
  font-family: "Open Sans", sans-serif;
  //line-height: 2;
}

.thead-light {
  border-bottom: solid 1px #e9e7e1 !important;
  text-transform: capitalize;
  font-weight: 700 !important;
  font-size: 0.9em !important;
  text-shadow: none;
  text-align: center;
}


@import './variables.scss';

.input-group-lg > .form-control, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {
  border-radius: 0rem;
}

input {
  border: #DAD6D4 solid 1px;
  font-weight: 400;
}

.btn {
  border-radius: 0px !important;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-placeholder;
  font-weight: 600;
  font-size: 16px;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $color-placeholder;
  font-weight: 600;
  font-size: 16px;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: $color-placeholder;
  font-weight: 600;
  font-size: 16px;
}

input {
  .input-bordered {
    border: #DAD6D4 solid 1px;
    color: $color-logo-darkblue;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    height: 46px;
    padding: 6px 12px;
  }
}
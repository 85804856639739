@import "./variables.scss";

body {
  margin: 0;
  padding: 0;
  font-family: $font-primary;
  font-weight: 400;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.field-error {
  font-family: $font-primary;
  color: red;
  font-size: 14px;
}

h2 {
  color: $color-logo-darkblue;
  //font-weight: 300;
  //font-size: 30px;
  font-family: $font-primary;
}

h4 {
  color: $color-logo-lightblue;
}


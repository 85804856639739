@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");
// My Bootstrap configuration
@import './custom_bootstrap';
// Bootstrap library
@import '~bootstrap/scss/bootstrap';
// Here you can import more .scss files or write scss code.
@import 'styles.scss';
@import 'text.scss';
@import 'table.scss';
@import 'form.scss';
@import 'src/components.common/navbar/navguest/navguest';
@import 'src/components.common/navbar/navlogo/navlogo';
@import 'src/components.common/footer/guestfooter/guestfooter';
@import '../components.pages/home/homepage';

.pointer {
  cursor: pointer;
}
.navguest-navbar {
  background-color: #ffffff;
  background: 0 0;
  border: none;
  //color: #fff;
  //z-index: 100;
  //margin-bottom: 0;
  //border-radius: 0;
}

.navguest-text {
  color: $color-logo-darkblue;
  font-size: 12px;
  background-color: transparent;
  font-family: $font-primary;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
}
@import "./variables.scss";

.text-md {
  font-family: $font-primary;
  font-size: 16px;
  color: #0C344E;
  font-weight: 300;
  line-height: 2;
}

.text-negative {
  color: $color-logo-red;
}

.text-color-red {
  color: $color-logo-red;
}

.text-color-orange {
  color: $color-logo-orange;
}

.text-color-darkblue {
  color: $color-logo-darkblue;
}

.text-color-lightblue {
  color: $color-logo-lightblue;
}

.text-color-green {
  color: $color-logo-green;
}